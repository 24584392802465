<script>
import ImageBackgroundVue from '@/components/ui/ImageBackground.vue'

export default {
  components: { ImageBackgroundVue },
  props: {
    title: {
      type: String,
      required: true
    },
    imgSrc: {
      type: String,
      required: true
    }
  },
  data() {
    return {}
  }
}
</script>

<template>
  <ImageBackgroundVue :src="imgSrc">
    <div class="wf__service-background">
      <div class="wf__service-subtitle">service</div>
      <div class="wf__service-title">{{ title }}</div>

      <div class="wf__service-divider"></div>

      <div class="wf__service-desc">
        연구실과 기업을 이어주는 든든한 디딤돌
        <div class="wf__service-desc-strong">유망연구실 통합플랫폼으로 시작합니다</div>
      </div>
    </div>
  </ImageBackgroundVue>
</template>

<style scoped>
.wf__service-background {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wf__service-subtitle {
  font-size: var(--wf-text-14);
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 1.5px;
}
.wf__service-title {
  font-size: var(--wf-text-48);
  font-weight: 600;
  translate: 0 -5px;
  line-height: 1.1;
}
.wf__service-divider {
  width: 48px;
  height: 3px;
  background-color: #fff;
  margin: 14px 0;
}
.wf__service-desc {
  margin-top: 16px;
  font-size: var(--wf-text-20);
  font-weight: 300;
  text-align: center;
}
.wf__service-desc-strong {
  font-weight: 600;
}
</style>
