<script>
import TopImageComponentVue from '@/components/introduce/TopImageComponent.vue'

import MainBackground from '@/assets/images/004.jpg'

export default {
  components: { TopImageComponentVue },
  data() {
    return {
      imgSrc: MainBackground
    }
  },
  mounted() {}
}
</script>

<template>
  <section class="wf__intro">
    <TopImageComponentVue :title="this.$route.meta.title" :imgSrc="imgSrc" />

    <router-view></router-view>
  </section>
</template>

<style scoped>
.wf__intro {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}

.wf__intro:deep(.wf__intro-title-badge) {
  color: var(--wf-primary-color);
  font-weight: 500;
}
.wf__intro:deep(.wf__intro-title) {
  font-size: 48px;
  font-weight: 700;
  line-height: 1;
}
.wf__intro:deep(.wf__intro-subtitle) {
  font-size: 16px;
  font-weight: 300;
  color: var(--wf-gray-400);
}

.wf__intro:deep(.wf__intro-desc-item) {
  display: flex;
  flex-direction: column;
  gap: 9px;
}
.wf__intro:deep(.wf__intro-desc-title) {
  font-size: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 7px;
}
.wf__intro:deep(.wf__intro-desc-title::before) {
  content: '';
  display: inline-block;
  width: 6px;
  height: 28px;
  background-color: var(--wf-primary-color);
}
.wf__intro:deep(.wf__intro-desc-content) {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.7;
  color: #707070;
}
</style>
